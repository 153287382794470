<div
  class="flex items-center justify-center overflow-hidden rounded-full border-2 bg-gray-400"
  [class.border-gold-500]="border === 'gold'"
  [class.border-purple-500]="border === 'purple'"
  [class.border-vermillon-500]="border === 'red'"
  [ngClass]="{'h-10 w-10': size === 'default', 'h-[50px] w-[50px]': size === 'sm', 'h-[36px] w-[36px]': size === 'xs'}"
>
  <img
    *ngIf="imageUrl; else noPhoto"
    [ngSrc]="imageUrl"
    [width]="portraitWidth"
    [height]="portraitHeight"
    [alt]="name"
    class="h-full w-full rounded-full object-cover"
    style="object-position: 0 20%"
    data-testid="support-portrait"
  />
  <ng-template #noPhoto>
    <span class="text-xl">{{ name[0] }}</span>
  </ng-template>
</div>
