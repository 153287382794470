import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {CommonModule, NgOptimizedImage} from '@angular/common'
import {DirectivesModule} from '../../../shared/directives/directives.module'

@Component({
  selector: 'cft-support-portrait',
  templateUrl: './support-portrait.component.html',
  styles: [':host {display: block}'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, DirectivesModule, NgOptimizedImage],
})
export class SupportPortraitComponent {
  @Input() name!: string
  @Input() imageUrl?: string
  @Input() border: 'gold' | 'purple' | 'red' = 'gold'
  @Input() size: 'xs' | 'sm' | 'default' = 'default'

  get portraitWidth() {
    switch (this.size) {
      case 'sm':
        return 50
      case 'xs':
        return 36
      default:
        return 76
    }
  }

  get portraitHeight() {
    return this.portraitWidth // square image
  }
}
